<template>
  <div>
    <FeeTypesFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="setFilterData"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(feeTypes)">
      <NoRecordFound />
    </div>

    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(type, index) in feeTypes" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Title', ['Title'])">
            {{ type.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Description')">
            {{ type.description }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :idx="index"
              :current-user="type"
              :action-list="actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>

    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`pagination${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(typesCounts, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />

    <UIConfirmationModal
      heading="DELETE_FEE_TYPE"
      message="Are you sure you want to delete "
      button-text="Delete"
      :modal="confirmation"
      :name="currentFeeType?.title"
      remaining-message="Fee Type ?"
      @cancel="toggleConfirmationModal"
      @confirm="deleteType"
    />
    <FeeTypeModal
      v-if="isFeeTypeModalDisplay"
      :modal="isFeeTypeModalDisplay"
      :edit="currentFeeType"
      @toggle="toggleFeeTypeModal"
    />
  </div>
</template>

<script>
import FeeTypesFilter from './FeeTypesFilter.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { isEmpty } from 'lodash'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import fileMixins from '@/src/mixins/file-mixins'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import { dropdownTopBottom, objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil.js'
import FEE_DATA from '@/src/router/views/finance/fees/received/received.json'
import FeeTypeModal from '@views/finance/fees/modals/AddAndUpdateFeeTypes.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    TableAction,
    FeeTypesFilter,
    Loader,
    Pagination,
    NoRecordFound,
    UIConfirmationModal,
    FeeTypeModal,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, fileMixins, scrollMixin],
  data() {
    return {
      isEmpty,
      GENERAL_CONSTANTS,
      forceRender: 1,
      fees: 'fees',
      skipForFilterColumns: FEE_DATA.feeTypeSkipForFilterColumns,
      defaultSelectedColumns: FEE_DATA.feeTypeDefaultSelectedColumns,
      tableHeaders: FEE_DATA.feeTypeTableHeaders,
      filtersData: {},
      filteredRecordLimit: GENERAL_CONSTANTS.RECORD_LIMIT,
      showPagination: false,
      typesCounts: null,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      currentFeeType: null,
      confirmation: false,
      isFeeTypeModalDisplay: false,
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      isLoading: (state) => state.layout.isLoading,
      feeTypes: (state) => state.fee.feeTypes?.records,
    }),
  },
  /**
   * Mounted hook
   * @description Setting static right bar data
   */
  mounted() {
    this.filterRecord()
  },
  methods: {
    showColumnInDataTable,
    dropdownTopBottom,
    toggleFeeTypeModal(payload) {
      this.isFeeTypeModalDisplay = !this.isFeeTypeModalDisplay
      if (!this.isFeeTypeModalDisplay) this.currentFeeType = null
      if (payload) this.filterRecord()
    },
    setFilterData(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
    },
    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
    },

    filterRecord(range) {
      this.$store.commit('layout/IS_LOADING', true)
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getFeeTypes(payload)
        .then((response) => {
          this.typesCounts = response?.data.meta.total_records
          this.showPagination = this.typesCounts > GENERAL_CONSTANTS.RECORD_LIMIT
          if (this.$route?.name === 'fee-types') this.setRightbarData(this.typesCounts)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },
    /**
     * Type Action
     * @param {string} eventName
     * @param {object} currentType
     * @param {number} idx
     * @returns {void}
     * @description Edit or delete current type on the basis of action
     */
    typeAction(action, currentType, idx) {
      switch (action) {
        case 'Edit':
          this.currentFeeType = currentType
          this.toggleFeeTypeModal()
          break
        case 'Delete':
          this.currentFeeType = currentType
          this.toggleConfirmationModal()
          break
      }
    },
    /**
     * Delete Type
     * @param {number} id - Current id of fee
     * @param {number} idx - Current index of fee id
     */
    deleteType() {
      const payload = { id: this.currentFeeType?.id, title: this.currentFeeType?.title }
      this.deleteFeeType(payload).then(() => {
        this.setRightbarData(this.feeTypes.length)
        this.currentFeeType = null
        this.confirmation = false
        this.forceRender++
        this.filterRecord()
      })
    },
    setRightbarData(length = 0) {
      const rightBarContent = {
        header: {
          title: 'Fee Type',
          buttons: [
            {
              title: 'Add Fee Type',
              classes: ['primary-button-right'],
              action: {
                func: () => {
                  this.toggleFeeTypeModal()
                },
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_NO_FEE_TYPES',
                value: length,
                tooltipDescription: 'Total types of Fee',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    ...mapActions('fee', ['getFeeTypes', 'deleteFeeType']),
  },
}
</script>

<style lang="scss" module>
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
