<template>
  <ValidationObserver @submit="addFeeTypes">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ modalTitle }}
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <InputBox
            v-model="feeType.title"
            type="text"
            name="FEE_TYPE"
            title="Fee Type"
            label="Fee Type"
            placeholder="Fee Type"
            class="flex-1"
            rules="required"
          />
          <InputBox
            v-model="feeType.description"
            type="text"
            name="Description"
            title="Description"
            label="Description"
            placeholder="Description"
            class="flex-1"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              <span v-if="!edit">Save</span>
              <span v-else>Update</span>
            </span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    UiModalContainer,
    InputBox,
    Loader,
    ValidationObserver,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      feeType: {
        title: '',
        description: '',
        is_active: true,
      },
      isLoading: false,
    }
  },
  computed: {
    modalTitle() {
      return this.edit ? 'Edit Fee Type' : 'Add Fee Type'
    },
  },
  created() {
    if (this.edit) this.setFeeTypeData()
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    addFeeTypes() {
      this.isLoading = true
      const data = {
        type: this.feeType,
      }
      if (this.edit) data.id = this.edit?.id
      this.addFeeType(data)
        .then(() => {
          this.$store.commit(
            'toast/NEW',
            {
              type: 'success',
              message: `${this.feeType.title} ${this.$t(
                this.edit ? 'toast.successfully updated' : 'toast.is added successfully',
              )}`,
            },
            { root: true },
          )
          this.$emit('toggle', 'fetch')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setFeeTypeData() {
      this.feeType.title = this.edit?.title
      this.feeType.description = this.edit?.description
    },
    ...mapActions('fee', ['addFeeType']),
  },
}
</script>
